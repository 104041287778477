import React from "react"
import { 
  Container, 
  Row, 
  Col 
} from "reactstrap"

const Footer = () => {
    return(
        <Container fluid className="mt-4 px-0">
          <Row noGutters>
            <Col className="footer-col">
              <footer>
                <span>
                  © {new Date().getFullYear()}, Powered by
                  {` `}
                  <a href="https://www.dgcom.eu">Dgcom S.r.l.</a>
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
    )
}

export default Footer