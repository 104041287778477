import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { 
  Container, 
  Row, 
  Col 
} from "react-bootstrap"
import CostumNavbar from "./navBar"
import Footer from "./footer"


const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main bg-light">
          <CostumNavbar pageInfo={pageInfo} />
          <Row noGutters>
            <Col>
              <Container className="bg-light">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    )}
  />
)

export default Layout
