import React from "react"
import { Link } from "gatsby"
import { 
  Navbar, 
  Nav 
} from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar className="shadow navbar-dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand className="title">AutoRiparazioni Pit Stop</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="dark-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/services" className="link-no-style">
              <Nav.Link className="text-light" as="span" eventKey="Servizi">
                Servizi
              </Nav.Link>
            </Link>
            <Link to="/gallery" className="link-no-style">
              <Nav.Link className="text-light" as="span" eventKey="Galleria">
                Galleria
              </Nav.Link>
            </Link>
            <Link to="/aboutUs" className="link-no-style">
              <Nav.Link className="text-light"as="span" eventKey="Chi siamo">
                Chi siamo
              </Nav.Link>
            </Link>
            <Link to="/contacts" className="link-no-style">
              <Nav.Link className="text-light" as="span" eventKey="Contatti">
                Contatti
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar